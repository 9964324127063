<script lang="ts" setup>
const props = defineProps({
  name: {
    type: String,
    default: ''
  }
})
</script>

<template>
  <svg :class="props.name">
    <use :xlink:href="`#${props.name}`"></use>
  </svg>
</template>

<style lang="scss" scoped>
svg {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
